import CustomerService from '@/services/customer.service';
import HttpClient from "@/helpers/http.client";
import DateHelper from "@/helpers/date.helper";
import LeadTracking from '@/components/LeadTracking/index.vue';
import CompanyService from "@/services/company.service";
import AppointmentsService from "@/services/appointment.service";
import SetApptStatusModal from "@/components/Appointments/SetStatusModal/index.vue";
import {mapActions} from "vuex";

const provider = new HttpClient();
const customerService = new CustomerService(provider);
const companyService = new CompanyService(provider);
const appointmentService = new AppointmentsService;

export default {
    name: 'daily-log',
    components: {
        LeadTracking,
        SetApptStatusModal
    },
    props: [],
    data() {
        return {
            createModal: false,
            locations: [],
            customers: [],
            appointments: [],
            appointment: {},
            dateRange: 'TODAY',
            customer: {
                Title: "Mr",
                FirstName: "",
                MiddleName: "",
                LastName: "",
                Nickname: "",
                Gender: "",
                Email: "",
                MobilePhone: "",
                HomePhone: "",
                WorkPhone: "",
                Phone: "",
                PhoneType: "MOBILE",
                TrackingSource: null,
                Birthday: "",
                Address: "",
                AddressType: "HOME",
                License: "",
                City: "",
                State: "",
                Zip: "",
                Apt: "",
                Country: "",
                Location: null,
                Languages: ['ENGLISH'],
                Tags: [],
            },
            Gender: [
                {
                    value: 'FEMALE',
                    text: 'Female'
                },
                {
                    value: 'MALE',
                    text: 'Male'
                },
                {
                    value: 'OTHER',
                    text: 'Other'
                }
            ],
            languageList: [
                {
                    "name": "AMHARIC"
                },
                {
                    "name": "ARABIC"
                },
                {
                    "name": "BASQUE"
                },
                {
                    "name": "BENGALI"
                },
                {
                    "name": "PORTUGUESE"
                },
                {
                    "name": "BULGARIAN"
                },
                {
                    "name": "CATALAN"
                },
                {
                    "name": "CHEROKEE"
                },
                {
                    "name": "CROATIAN"
                },
                {
                    "name": "CZECH"
                },
                {
                    "name": "DANISH"
                },
                {
                    "name": "DUTCH"
                },
                {
                    "name": "ENGLISH"
                },
                {
                    "name": "ESTONIAN"
                },
                {
                    "name": "FILIPINO"
                },
                {
                    "name": "FINNISH"
                },
                {
                    "name": "FRENCH"
                },
                {
                    "name": "GERMAN"
                },
                {
                    "name": "GREEK"
                },
                {
                    "name": "GUJARATI"
                },
                {
                    "name": "HEBREW"
                },
                {
                    "name": "HINDI"
                },
                {
                    "name": "HUNGARIAN"
                },
                {
                    "name": "ICELANDIC"
                },
                {
                    "name": "INDONESIAN"
                },
                {
                    "name": "ITALIAN"
                },
                {
                    "name": "JAPANESE"
                },
                {
                    "name": "KANNADA"
                },
                {
                    "name": "KOREAN"
                },
                {
                    "name": "LATVIAN"
                },
                {
                    "name": "LITHUANIAN"
                },
                {
                    "name": "MALAY"
                },
                {
                    "name": "MALAYALAM"
                },
                {
                    "name": "MARATHI"
                },
                {
                    "name": "NORWEGIAN"
                },
                {
                    "name": "ROMANIAN"
                },
                {
                    "name": "RUSSIAN"
                },
                {
                    "name": "SERBIAN"
                },
                {
                    "name": "CHINESE"
                },
                {
                    "name": "SLOVAK"
                },
                {
                    "name": "SLOVENIAN"
                },
                {
                    "name": "SPANISH"
                },
                {
                    "name": "SWAHILI"
                },
                {
                    "name": "SWEDISH"
                },
                {
                    "name": "TAMIL"
                },
                {
                    "name": "TELUGU"
                },
                {
                    "name": "THAI"
                },
                {
                    "name": "TAIWAN"
                },
                {
                    "name": "TURKISH"
                },
                {
                    "name": "URDU"
                },
                {
                    "name": "UKRAINIAN"
                },
                {
                    "name": "VIETNAMESE"
                },
                {
                    "name": "WELSH"
                }
            ],
            deleteModal: {
                visible: false,
                customerId: '',
            },
            customerCopy: {},
            emptyCustomers: false,

            validation: {},

            rescheduleModal: false,
            apptStatusModal: null,

            appointmentCount: {
                total: 0,
                today: 0,
            },
            editModal: false,
            btnLoading: false,
            appointmentCurrentPage: 1,
            appointmentTotalRows: 0,
            appointmentPerPage: 50
        }
    },
    computed: {},
    async mounted() {
        await this.dailyLogList();
        await this.appointmentList();
    },
    methods: {
        ...mapActions(['addLoading', 'delLoading',]),

        async dailyLogList() {
            const res = await customerService.DailyLoglist();
            if (res && !res.error) {
                this.customers = res.data.customers
            }
        },

        async saveCustomer() {
            const res = await customerService.create(this.customer);
            if (res && !res.error) {
                this.createModal = false;
                await this.dailyLogList();
                this._resetCustomer();
                this.$notify({type: 'success', message: this.$t('customer_created')});
            } else if (res.error) {
                this.validation = res.data;
            }
        },

        async getLocations() {
            const res = await companyService.locations();
            if (res && !res.error) {
                this.locations = res.data;
            }
        },

        async appointmentList(page, paginate) {

            this.appointmentCurrentPage = page || 1;
            const skip = this.appointmentPerPage * (this.appointmentCurrentPage - 1);

            const filters = {
                company: this.company,
                agent: this.agent,
            };

            if (this.dateRange !== 'ALL_TIME') {
                filters.dateRange = this.dateRange;
            }

            const res = await appointmentService.list1(this.appointmentPerPage, skip, filters);
            if (res && !res.error) {
                this.appointments = !paginate ? res.data.appts : [...this.appointments, ...res.data.appts];
                this.appointmentTotalRows = res.data.count;
            }
        },

        parserAgentName(agent) {
            if (agent) {
                return `${agent.FirstName} ${agent.LastName}`
            } else {
                return 'N/A'
            }
        },

        cancelModal() {
            this.createModal = false;
            this.deleteModal.visible = false;
            this.btnLoading = false;
            this.rescheduleModal = false;
            this.apptStatusModal = null;
        },

        openModal(type, appt) {
            this.resetAppt(appt);

            switch (type) {
                case "reschedule":
                    this.rescheduleModal = true;
                    break;
                case "create":
                    this.searchCar = '';
                    this.createModal = true;
                    break;
                case "edit":
                    this.editModal = true;
                    break;
                case "assign_car":
                    this.searchCar = '';
                    this.selectCarModal = true;
                    break;
                case "delete_car":
                    this.deleteCarModal.show = true;
                    break;
                case "complete":
                    this.apptStatusModal = 'complete'
                    break;
                case "cancel":
                    this.apptStatusModal = 'cancel'
                    break;
                case "assign_agent":
                    this.apptStatusModal = 'assign'
                    break;
            }
        },

        resetAppt(appt) {
            if (appt) {
                this.appointment = appt;
            } else {
                this.appointment = {
                    FirstName: "",
                    LastName: "",
                    Email: "",
                    Phone: "",
                    Agent: "",
                    DueDate: DateHelper.customFormat(this.daySelected),
                    DueTime: "",
                    Place: "",
                    Notes: "",
                    Car: ""
                }
            }
        },

        onTrackingSelected(tracking) {
            this.customer.TrackingSource = tracking._id;
        },

        async onApptChange() {
            await this.appointmentList();
        },

        showApptActions(appt) {
            return !(appt.Completed || appt.Canceled) &&
                (this.$hasRole('admin') || this.$hasRole('reception')) ||
                (appt.Agent === this.$getUser()._id) ||
                (appt.Assigned && appt.AssignedTo._id === this.$getUser()._id)
        },

        appointmentStatus(appt) {
            const currentDate = new Date(),
                apptDate = new Date(appt.Date);

            if (appt.Completed) {
                return 'completed';
            } else if (appt.Canceled) {
                return 'canceled';
            } else if (apptDate.getTime() - currentDate.getTime() < 0) {
                return 'past_due';
            } else {
                return 'scheduled';
            }
        },

        _resetCustomer() {
            this.customer = {
                Title: "Mr",
                FirstName: "",
                MiddleName: "",
                LastName: "",
                Nickname: "",
                Email: "",
                Phone: "",
                PhoneType: "MOBILE",
                Birthday: "",
                Address: "",
                AddressType: "HOME",
                City: "",
                State: "",
                Zip: "",
                Apt: "",
                Country: "",
                Location: null,
                Languages: ['ENGLISH'],
                Tags: [],
            }
        }
    },
    watch: {
        createModal: {
            handler(value) {
                this._resetCustomer();
                if (value && this.locations.length <= 0) {
                    this.getLocations();
                }
            }
        },
    },

    filters: {
        dateHours(date) {
            return DateHelper.customFormat(date, 'hh:mm A');
        },
    }
}


